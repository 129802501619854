import { render, staticRenderFns } from "./PublicPage.vue?vue&type=template&id=aa366eda&scoped=true&"
import script from "./PublicPage.vue?vue&type=script&lang=js&"
export * from "./PublicPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa366eda",
  null
  
)

export default component.exports